<template>
  <b-dropdown :triggers="['contextmenu']" scrollable max-height="400">
    <b-button
      size="is-small"
      class="has-text-grey"
      icon-right="menu-down"
      slot="trigger"
      >{{ firstCategory || "Select Category" }}</b-button
    >
    <b-dropdown-item @click.native="category_ids = []">
      None
    </b-dropdown-item>
    <template v-for="mc in $store.state.products.data.categories">
      <b-dropdown-item :key="mc.id" custom>
        <b-checkbox
          type="is-success"
          size="is-small"
          :native-value="mc.id"
          v-model="category_ids"
          @change.native="excludeChildren(mc.id, mc.children)"
          @click.native="saveValues()"
          >{{ mc.name }}</b-checkbox
        >
      </b-dropdown-item>
      <template v-for="sc in mc.children">
        <b-dropdown-item :key="sc.id" custom class="ml-4 -my-2">
          <b-checkbox
            type="is-success"
            size="is-small"
            :native-value="sc.id"
            v-model="category_ids"
            @change.native="includeParent(sc.id, sc.category_id)"
            @click.native="saveValues()"
            >{{ sc.name }}</b-checkbox
          >
        </b-dropdown-item>
      </template>
    </template>
  </b-dropdown>
</template>

<script>
import store from "@/store";
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    active: false,
    category_ids: [],
    firstCategory: ""
  }),
  watch: {
    category_ids() {
      if (this.category_ids.length) {
        let arr = [];
        _.forEach(this.category_ids, id => {
          let cat = _.find(this.$store.state.products.data.categories, [
            "id",
            id
          ]);
          if (cat) arr.push(cat);
        });
        this.firstCategory = arr.length ? arr[0].name : "";
      } else {
        this.firstCategory = "";
      }
    }
  },
  methods: {
    excludeChildren(id, children) {
      this.$nextTick(() => {
        if (!_.includes(this.category_ids, id)) {
          _.forEach(children, child => {
            let index = _.indexOf(this.category_ids, child.id);
            if (index > -1) this.category_ids.splice(index, 1);
          });
        }
      });
    },
    includeParent(id, parent_id) {
      this.$nextTick(() => {
        if (_.includes(this.category_ids, id)) {
          if (!_.includes(this.category_ids, parent_id))
            this.category_ids.push(parent_id);
        }
      });
    },
    saveValues: _.debounce(function() {
      this.$store.dispatch("saveProductsForm", {
        id: this.id,
        category_ids: this.category_ids
      });
    }, store.state.global.delay)
  },
  mounted() {
    this.category_ids = _.map(this.data, "id");
  }
};
</script>
