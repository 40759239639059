import { render, staticRenderFns } from "./QuickEdit.vue?vue&type=template&id=448bd14a&scoped=true&"
import script from "./QuickEdit.vue?vue&type=script&lang=js&"
export * from "./QuickEdit.vue?vue&type=script&lang=js&"
import style0 from "./QuickEdit.vue?vue&type=style&index=0&id=448bd14a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448bd14a",
  null
  
)

export default component.exports