<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12">
      <div class="flex items-center mb-4">
        <div class="is-size-4">{{ $route.name }}</div>
        <b-button
          class="ml-4"
          type="is-success"
          tag="router-link"
          :to="{ name: 'Create Product' }"
          v-if="userHasPermission('write-products')"
          >New</b-button
        >
        <b-select
          v-model="selectedCategory"
          class="ml-4"
          @change.native="onSelect"
        >
          <option value="*">All</option>
          <option
            v-for="(cat, index) in data.categories"
            :key="index"
            :value="cat.slug"
            >{{ cat.name }}</option
          >
        </b-select>
        <div class="flex-grow"></div>
        <div class="flex items-center">
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            :close-on-click="false"
            :triggers="['contextmenu', 'click']"
          >
            <button class="button" slot="trigger" slot-scope="{ active }">
              <span>Options</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>

            <b-dropdown-item aria-role="listitem" custom class="">
              <div class="heading">Fields</div>
              <div class="my-2">
                <template
                  v-for="(vc, index) in $store.getters.productsVisibleColumns"
                >
                  <b-checkbox
                    v-if="index == 'cat' && userHasPermission('write-products')"
                    class="mb-2 w-full"
                    v-model="vc.visible"
                    :key="index"
                    type="is-success"
                    >{{ vc.label }}</b-checkbox
                  >
                  <b-checkbox
                    v-if="index != 'cat'"
                    class="mb-2 w-full"
                    v-model="vc.visible"
                    :key="index"
                    type="is-success"
                    >{{ vc.label }}</b-checkbox
                  >
                </template>
              </div>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" custom>
              <div class="heading">Items Per Page</div>
              <div class="my-2">
                <input
                  type="number"
                  class="border apperance-none"
                  v-model="perPage"
                  step="25"
                  min="25"
                  @input="onPerPageChange"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center">
          <b-button
            icon-left="checkbox-marked-outline"
            class=""
            @click="(checkable = !checkable), (checkedRows = [])"
            :disabled="$store.getters.isProcessing"
            v-if="userHasPermission('write-products')"
            >Select</b-button
          >
          <b-button
            v-if="checkedRows.length"
            icon-left="delete-outline"
            class="ml-4"
            @click="onDelete()"
            :disabled="$store.getters.isProcessing"
            >Delete ({{ checkedRows.length }})</b-button
          >
          <b-button
            v-if="checkedRows.length"
            icon-left="close"
            class="ml-4"
            @click="checkedRows = []"
            :disabled="$store.getters.isProcessing"
            >Clear</b-button
          >
        </div>
        <div @keyup.enter="onSearch">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </div>
      <b-table
        :data="data.data"
        :loading="$store.getters.isLoading && !$store.getters.isRouting"
        paginated
        pagination-position="bottom"
        backend-pagination
        :total="data.total"
        :per-page="data.per_page"
        :current-page="data.current_page"
        @page-change="onPageChange"
        backend-sorting
        :default-sort="[
          $store.state.categories.column,
          $store.state.categories.order
        ]"
        @sort="onSort"
        striped
        :checkable="checkable"
        checkbox-position="left"
        :checked-rows.sync="checkedRows"
        detailed
        custom-detail-row
        :opened-detailed="$store.getters.productsOpenedRows"
        detail-key="id"
        show-detail-icon
      >
        <!-- columns -->
        <b-table-column field="image" label="Image" width="20" v-slot="props">
          <b-image
            :src="
              props.row.image ? props.row.image.thumb : $default_image_thumb
            "
            :alt="props.row.slug"
            ratio="1by1"
            lazy
          ></b-image>
        </b-table-column>
        <b-table-column field="code" label="Code" v-slot="props" sortable>
          {{ props.row.code }}
        </b-table-column>
        <b-table-column field="name" label="Name" v-slot="props" sortable>
          {{ props.row.name }}
          <div>
            <router-link
              :to="{ name: 'Edit Product', params: { id: props.row.slug } }"
              class="is-size-7 is-link px-2 border"
              v-if="userHasPermission('write-products')"
              >Edit</router-link
            >
            <router-link
              :to="{ name: 'Create Product', query: { id: props.row.id } }"
              class="is-size-7 is-link px-2 border ml-2"
              v-if="userHasPermission('write-products')"
              >Add Variant</router-link
            >
          </div>
        </b-table-column>
        <b-table-column
          field="categories_count"
          label="Cat"
          v-slot="props"
          :visible="$store.getters.productsVisibleColumns.cat.visible"
          sortable
        >
          <QuickEditCategories
            :key="props.row.id"
            :data="props.row.categories"
            :id="props.row.slug"
          />
        </b-table-column>
        <b-table-column
          field="price_wholesale"
          label="Wholesale"
          v-slot="props"
          sortable
          v-if="
            userHasPermission('write-prices') ||
              userHasPermission('read-prices') ||
              $store.getters.userRole === 'super-admin' ||
              $store.getters.userRole === 'sales-ph'
          "
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="price_wholesale"
            :value="props.row.price_wholesale"
            :orig="props.row.price_wholesale"
            :decimal="true"
            @updated="props.row.price_wholesale = $event"
          />
        </b-table-column>
        <b-table-column
          field="price_partner"
          label="Partner"
          v-slot="props"
          sortable
          v-if="
            userHasPermission('write-prices') ||
              userHasPermission('read-prices') ||
              $store.getters.userRole === 'super-admin' ||
              $store.getters.userRole === 'sales-ph'
          "
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="price_partner"
            :value="props.row.price_partner"
            :orig="props.row.price_partner"
            :decimal="true"
            @updated="props.row.price_partner = $event"
          />
        </b-table-column>
        <b-table-column
          field="price_direct_sales"
          label="Direct"
          v-slot="props"
          sortable
          v-if="
            userHasPermission('write-prices') ||
              userHasPermission('read-prices') ||
              $store.getters.userRole === 'super-admin' ||
              $store.getters.userRole === 'sales-ph'
          "
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="price_direct_sales"
            :value="props.row.price_direct_sales"
            :orig="props.row.price_direct_sales"
            :decimal="true"
            @updated="props.row.price_direct_sales = $event"
          />
        </b-table-column>
        <b-table-column
          field="length"
          label="Length"
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.length.visible"
        >
          <!-- {{ props.row.length }} -->
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="length"
            :value="props.row.length"
            :orig="props.row.length"
            :decimal="false"
            @updated="props.row.length = $event"
          />
        </b-table-column>
        <b-table-column
          field="width"
          label="Width"
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.width.visible"
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="width"
            :value="props.row.width"
            :orig="props.row.width"
            :decimal="false"
            @updated="props.row.width = $event"
          />
        </b-table-column>
        <b-table-column
          field="height"
          label="Height"
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.height.visible"
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="height"
            :value="props.row.height"
            :orig="props.row.height"
            :decimal="false"
            @updated="props.row.height = $event"
          />
        </b-table-column>
        <b-table-column
          field="volume_packed"
          label="CBM"
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.volume_packed.visible"
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="volume_packed"
            :value="props.row.volume_packed"
            :orig="props.row.volume_packed"
            :decimal="true"
            @updated="props.row.volume_packed = $event"
          />
        </b-table-column>
        <b-table-column
          field="parts"
          label="Parts"
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.parts.visible"
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="parts"
            :value="props.row.parts"
            :orig="props.row.parts"
            :decimal="false"
            @updated="props.row.parts = $event"
          />
        </b-table-column>
        <b-table-column
          field="weight_net"
          label="Net Wt."
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.weight_net.visible"
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="weight_net"
            :value="props.row.weight_net"
            :orig="props.row.weight_net"
            :decimal="true"
            @updated="props.row.weight_net = $event"
          />
        </b-table-column>
        <b-table-column
          field="weight_gross"
          label="Gross Wt."
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.weight_gross.visible"
        >
          <QuickEdit
            :key="props.row.id"
            :id="props.row.slug"
            column="weight_gross"
            :value="props.row.weight_gross"
            :orig="props.row.weight_gross"
            :decimal="true"
            @updated="props.row.weight_gross = $event"
          />
        </b-table-column>
        <b-table-column
          field="is_illuminated"
          label="Illmntd."
          v-slot="props"
          sortable
          :visible="
            $store.getters.productsVisibleColumns.is_illuminated.visible
          "
        >
          {{ props.row.is_illuminated ? "Yes" : "No" }}
        </b-table-column>
        <b-table-column
          field="updated_at"
          label="Updated"
          v-slot="props"
          sortable
          :visible="$store.getters.productsVisibleColumns.updated_at.visible"
        >
          {{ props.row.updated_at }}
        </b-table-column>
        <b-table-column
          field="created_at"
          label="Created"
          v-slot="props"
          sortable
        >
          <!-- {{ props.row.created_at }} -->
          <QuickEditCreatedAt
            :key="props.row.id"
            :id="props.row.slug"
            column="created_at"
            :value="props.row.created_at"
            :orig="props.row.created_at"
            @updated="props.row.created_at = $event"
          />
        </b-table-column>
        <!-- !columns -->
        <template slot="detail" slot-scope="props">
          <tr v-for="item in props.row.variants" :key="item.slug">
            <td v-if="checkable"></td>
            <td>
              <b-checkbox
                v-if="checkable"
                v-model="checkedRows"
                :native-value="item"
              ></b-checkbox>
            </td>
            <td>
              <b-image
                :src="item.image ? item.image.thumb : $default_image_thumb"
                :alt="item.slug"
                ratio="1by1"
                lazy
              ></b-image>
            </td>
            <td>{{ item.code }}</td>
            <td>
              <div>{{ item.name }}</div>
              <div>
                <router-link
                  :to="{ name: 'Edit Product', params: { id: item.slug } }"
                  class="is-size-7 is-link px-2 border"
                  v-if="userHasPermission('write-products')"
                  >Edit</router-link
                >
              </div>
            </td>
            <td v-if="$store.getters.productsVisibleColumns.cat.visible">
              <QuickEditCategories
                :key="props.row.id"
                :data="props.row.categories"
                :id="props.row.slug"
              />
            </td>
            <td
              v-if="
                userHasPermission('read-prices') ||
                  userHasPermission('write-prices') ||
                  $store.getters.userRole === 'super-admin' ||
                  $store.getters.userRole === 'sales-ph'
              "
            >
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="price_wholesale"
                :value="item.price_wholesale"
                :orig="item.price_wholesale"
                :decimal="true"
                @updated="item.price_wholesale = $event"
              />
            </td>
            <td
              v-if="
                userHasPermission('read-prices') ||
                  userHasPermission('write-prices') ||
                  $store.getters.userRole === 'super-admin' ||
                  $store.getters.userRole === 'sales-ph'
              "
            >
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="price_partner"
                :value="item.price_partner"
                :orig="item.price_partner"
                :decimal="true"
                @updated="item.price_partner = $event"
              />
            </td>
            <td
              v-if="
                userHasPermission('read-prices') ||
                  userHasPermission('write-prices') ||
                  $store.getters.userRole === 'super-admin' ||
                  $store.getters.userRole === 'sales-ph'
              "
            >
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="price_direct_sales"
                :value="item.price_direct_sales"
                :orig="item.price_direct_sales"
                :decimal="true"
                @updated="item.price_direct_sales = $event"
              />
            </td>
            <td v-if="$store.getters.productsVisibleColumns['length'].visible">
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="length"
                :value="item.length"
                :orig="item.length"
                :decimal="false"
                @updated="item.length = $event"
              />
            </td>
            <td v-if="$store.getters.productsVisibleColumns['width'].visible">
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="width"
                :value="item.width"
                :orig="item.width"
                :decimal="false"
                @updated="item.width = $event"
              />
            </td>
            <td v-if="$store.getters.productsVisibleColumns['height'].visible">
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="height"
                :value="item.height"
                :orig="item.height"
                :decimal="false"
                @updated="item.height = $event"
              />
            </td>
            <td
              v-if="
                $store.getters.productsVisibleColumns['volume_packed'].visible
              "
            >
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="volume_packed"
                :value="item.volume_packed"
                :orig="item.volume_packed"
                :decimal="true"
                @updated="item.volume_packed = $event"
              />
            </td>
            <td v-if="$store.getters.productsVisibleColumns['parts'].visible">
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="parts"
                :value="item.parts"
                :orig="item.parts"
                :decimal="false"
                @updated="item.parts = $event"
              />
            </td>
            <td
              v-if="$store.getters.productsVisibleColumns['weight_net'].visible"
            >
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="weight_net"
                :value="item.weight_net"
                :orig="item.weight_net"
                :decimal="true"
                @updated="item.weight_net = $event"
              />
            </td>
            <td
              v-if="
                $store.getters.productsVisibleColumns['weight_gross'].visible
              "
            >
              <QuickEdit
                :key="item.id"
                :id="item.slug"
                column="weight_gross"
                :value="item.weight_gross"
                :orig="item.weight_gross"
                :decimal="true"
                @updated="item.weight_gross = $event"
              />
            </td>
            <td
              v-if="
                $store.getters.productsVisibleColumns['is_illuminated'].visible
              "
            >
              {{ item.is_illuminated ? "Yes" : "No" }}
            </td>
            <td
              v-if="$store.getters.productsVisibleColumns['updated_at'].visible"
            >
              {{ item.updated_at }}
            </td>
            <td>
              <!-- {{ item.created_at }} -->
              <QuickEditCreatedAt
                :key="item.id"
                :id="item.slug"
                column="created_at"
                :value="item.created_at"
                :orig="item.created_at"
                @updated="item.created_at = $event"
              />
            </td>
          </tr>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import QuickEdit from "@/components/admin/QuickEdit";
import QuickEditCreatedAt from "@/components/admin/QuickEditCreatedAt";
import QuickEditCategories from "@/components/admin/QuickEditCategories";
import store from "@/store";
export default {
  components: {
    Sidebar,
    QuickEdit,
    QuickEditCreatedAt,
    QuickEditCategories
  },
  data: () => ({
    // visibleColumns: {
    //   cat: {
    //     name: "cat",
    //     label: "Category",
    //     visible: false
    //   },
    //   length: {
    //     name: "length",
    //     label: "Length",
    //     visible: true
    //   },
    //   width: {
    //     name: "width",
    //     label: "Width",
    //     visible: true
    //   },
    //   height: {
    //     name: "height",
    //     label: "Height",
    //     visible: true
    //   },
    //   volume_packed: {
    //     name: "volume_packed",
    //     label: "Packed Volume",
    //     visible: false
    //   },
    //   parts: {
    //     name: "parts",
    //     label: "No. of Parts",
    //     visible: false
    //   },
    //   weight_net: {
    //     name: "weight_net",
    //     label: "Net Weight",
    //     visible: false
    //   },
    //   weight_gross: {
    //     name: "weight_gross",
    //     label: "Gross Weight",
    //     visible: false
    //   },
    //   is_illuminated: {
    //     name: "is_illuminated",
    //     label: "Light",
    //     visible: false
    //   },
    //   updated_at: {
    //     name: "updated_at",
    //     label: "Updated",
    //     visible: false
    //   }
    // },
    checkable: false,
    checkedRows: []
    // openedRows: []
  }),
  computed: {
    data() {
      return this.$store.state.products.data;
    },
    search: {
      get() {
        return this.$store.state.products.search;
      },
      set(newValue) {
        store.commit("setProductsSearch", newValue);
      }
    },
    perPage: {
      get() {
        return this.$store.state.products.perPage;
      },
      set(newValue) {
        store.commit("setProductsPerPage", newValue);
      }
    },
    selectedCategory: {
      get() {
        return this.$store.state.products.selectedCategory;
      },
      set(newValue) {
        store.commit("setProductsSelectedCategory", newValue);
      }
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.onSearch();
    }, store.getters.delay)
    // data() {
    //   this.openedRows = _.map(this.data.data, p => {
    //     if (p.variants.length) return p.id;
    //   });
    // }
  },
  mounted() {
    // store.dispatch("getHomeData");
  },
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    onSearch() {
      store.dispatch(this.$route.meta.dispatch);
    },
    onPageChange(page) {
      store.commit("setProductsPage", page);
      store.dispatch(this.$route.meta.dispatch);
    },
    onSort(column, order) {
      store.commit("setProductsPage", 1);
      store.commit("setProductsColumn", column);
      store.commit("setProductsOrder", order);
      store.dispatch(this.$route.meta.dispatch);
    },
    onPerPageChange() {
      store.dispatch(this.$route.meta.dispatch);
    },
    onDelete() {
      store
        .dispatch("deleteSelection", {
          message: `Do you really want to delete these products?`,
          action: "deleteSelection",
          ids: _.map(this.checkedRows, "id"),
          path: "products",
          push: "Products",
          dispatch: this.$route.meta.dispatch
        })
        .then(() => {
          this.checkedRows = [];
          this.checkable = false;
        });
    },
    onSelect() {
      store.dispatch(this.$route.meta.dispatch);
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch, {}).then(() => {
        next();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  width: 100px;
  padding: 4px 7px;
  border-radius: 4px;
}
</style>
