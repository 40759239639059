<template>
  <div @contextmenu.prevent="enabled = true" class="is-relative">
    <input
      v-if="userHasPermission('write-products')"
      ref="input"
      type="number"
      :step="decimal ? '0.5' : '1'"
      min="0"
      v-model="localValue"
      @keyup.esc="enabled = false"
      @keyup.enter="enabled = false"
      :disabled="!enabled"
      class="border border-transparent appearance-none"
      :class="[isFocused, status]"
    />
    <span v-else>{{ localValue }}</span>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    column: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    orig: {
      required: true
    },
    decimal: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    enabled: false,
    localValue: null,
    status: "default"
  }),
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    saveValue: _.debounce(function() {
      if (this.localValue == this.orig) {
        return;
      } else {
        let data = {
          id: this.id,
          column: this.column,
          value: this.localValue
        };
        data[this.column] = this.localValue;
        store
          .dispatch("saveProductsForm", data)
          .then(() => {
            this.inputStatus("success");
            if (this.decimal)
              this.localValue = parseFloat(this.localValue).toFixed(2);
            this.$emit("updated", this.localValue);
          })
          .catch(() => {
            this.inputStatus("failed");
            if (this.decimal)
              this.localValue = parseFloat(this.localValue).toFixed(2);
            else this.localValue = this.orig;
          });
      }
    }, store.state.global.delay),
    inputStatus(type) {
      this.status = type;
      setTimeout(() => {
        this.status = "default";
      }, store.state.global.delay * 3);
    },
    toggleInput(e) {
      if (_.isUndefined(this.$refs["input"])) return;
      if (!this.$refs["input"].contains(e.target)) this.enabled = false;
    },
    focusInput() {
      this.$nextTick(() => {
        if (_.isUndefined(this.$refs["input"])) return;
        this.$refs["input"].focus();
      });
    }
  },
  watch: {
    enabled() {
      if (this.enabled) {
        window.addEventListener("click", this.toggleInput);
        this.focusInput();
      } else {
        window.removeEventListener("click", this.toggleInput);
        this.saveValue();
      }
    }
  },
  computed: {
    isFocused() {
      return this.enabled ? "border-green-100" : "";
    }
  },
  mounted() {
    this.localValue = this.value;
  }
};
</script>

<style lang="scss" scoped>
input {
  width: 100px;
  padding: 4px 7px;
  border-radius: 4px;
}

.success {
  background: lighten(hsl(141, 53%, 53%), 40%);
}

.failed {
  background: lighten(hsl(348, 86%, 61%), 40%);
}

.default {
  background: white;
}
</style>
